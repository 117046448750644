$primary: #2961ec;
$secondary: #00E0C7;

$danger: #e35e39;


.primary {
    color: $primary !important;
}

.secondary {
    color: $secondary !important;
}

.danger {
    color: $danger !important;
}

.bg-primary {
    background-color: $primary !important;
}