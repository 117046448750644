@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import './variables.scss';
@import '../../components/components';


body,
html {
    font-family: 'Jost', sans-serif !important;
    margin: 0;
    padding: 0;
    font-size: 14px !important;
}

.ant-btn {
    &-primary {
        background-color: $primary;
    }
}

.ant-input-lg,
.ant-picker-input>input {
    font-size: 13px !important;
    // height: 46px !important;
}

// .ant-picker,
// .ant-input-affix-wrapper {
//     padding: 0px 10px !important;
// }

.ant-input-group-addon {
    display: none !important;
}

.ant-input-affix-wrapper {
    border-radius: 8px !important;
}