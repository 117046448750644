#Tabs {
    position: fixed;
    bottom: 10px;
    right: 10px;
    left: 10px;

    .tab {
        &.active {
            background-color: rgba($primary, .1);
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}

#Header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 99;
}

#Loading {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 999;
}